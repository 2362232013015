import { post } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";
const API_PUBLIC = "/api/Prueba/V1/query";

export default {
  getAll: (payload) => {
    const { provincia, distrito, search, edad } = payload;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_reporte_padron",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@SEARCH",
          value: `%${search}%`,
          type: "string"
        },
        {
          name: "@EDAD",
          value: `%${edad}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllProvincia: (tipopaciente = "", fecha_corte, dia_inicio, dia_final) => {

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: (dia_inicio == 0) ? "web_data_reporte_padron_provincia_reporte" : "web_data_reporte_padron_provincia",
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${tipopaciente}%`,
          type: "string"
        },
        {
          name: "@FECHA_CORTE",
          value: fecha_corte,
          type: "string"
        },
        {
          name: "@DIA_INICIO",
          value: dia_inicio,
          type: "int"
        },
        {
          name: "@DIA_FINAL",
          value: dia_final,
          type: "int"
        }
      ]
    };

    console.log("getAllProvincia", request);

    return post(API_PUBLIC, request);
  },

  getAllProvinciaGestante: (tipopaciente = "", fecha_inicio, fecha_fin) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_reporte_padron_provincia_gestantes_v2",
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${tipopaciente}%`,
          type: "string"
        },
        {
          name: "@FECHA_INICIO",
          value: `${fecha_inicio}`,
          type: "string"
        },
        {
          name: "@FECHA_FIN",
          value: `${fecha_fin}`,
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getAllDistrito: (provincia, tipopaciente, fecha_corte, dia_inicio, dia_final) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: (dia_inicio == 0) ? "web_data_reporte_padron_distrito_reporte" : "web_data_reporte_padron_distrito",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${tipopaciente}%`,
          type: "string"
        },
        {
          name: "@FECHA_CORTE",
          value: fecha_corte,
          type: "string"
        },
        {
          name: "@DIA_INICIO",
          value: dia_inicio,
          type: "int"
        },
        {
          name: "@DIA_FINAL",
          value: dia_final,
          type: "int"
        }
      ]
    };

    console.log("getAllDistrito", request);

    return post(API_PUBLIC, request);
  },

  getAllDistritoGestante: (provincia, tipopaciente, fecha_inicio, fecha_fin) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_reporte_padron_distrito_gestante_v2",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${tipopaciente}%`,
          type: "string"
        },
        {
          name: "@FECHA_INICIO",
          value: `${fecha_inicio}`,
          type: "string"
        },
        {
          name: "@FECHA_FIN",
          value: `${fecha_fin}`,
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getAllCentroPoblado: (provincia, distrito, tipopaciente, fecha_corte, dia_inicio, dia_final) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_reporte_padron_centro_poblado",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${tipopaciente}%`,
          type: "string"
        },
        {
          name: "@FECHA_CORTE",
          value: fecha_corte,
          type: "string"
        },
        {
          name: "@DIA_INICIO",
          value: dia_inicio,
          type: "int"
        },
        {
          name: "@DIA_FINAL",
          value: dia_final,
          type: "int"
        }
      ]
    };

    console.log("getAllCentroPoblado", request);

    return post(API_PUBLIC, request);
  },

  getAllCentroPobladoGestante: (provincia, distrito, tipopaciente) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_reporte_padron_centro_poblado_gestante",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${tipopaciente}%`,
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getAllEstablecimiento: (
    provincia,
    distrito,
    tipopaciente,
    fecha_corte,
    dia_inicio,
    dia_final
  ) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: (dia_inicio == 0) ? "web_data_reporte_padron_establecimiento_reporte" : "web_data_reporte_padron_establecimiento",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${tipopaciente}%`,
          type: "string"
        },
        {
          name: "@FECHA_CORTE",
          value: fecha_corte,
          type: "string"
        },
        {
          name: "@DIA_INICIO",
          value: dia_inicio,
          type: "int"
        },
        {
          name: "@DIA_FINAL",
          value: dia_final,
          type: "int"
        }
      ]
    };

    console.log("getAllEstablecimiento", request);

    return post(API_PUBLIC, request);
  },

  getAllEstablecimientoGestante: (provincia, distrito, tipopaciente, fecha_inicio, fecha_fin) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_reporte_padron_establecimiento_gestante_v2",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${tipopaciente}%`,
          type: "string"
        },
        {
          name: "@FECHA_INICIO",
          value: `${fecha_inicio}`,
          type: "string"
        },
        {
          name: "@FECHA_FIN",
          value: `${fecha_fin}`,
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  }
};
