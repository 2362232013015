export const columns1 = [
  {
    title: "PROVINCIA",
    dataIndex: "nombre",
    key: "nombre"
  },
  {
    title: "CANTIDAD",
    dataIndex: "total_formateado",
    key: "total_formateado",
    slots: { customRender: "total_formateado" },
    defaultSortOrder: "descend",
    sorter: (a, b) => a.total - b.total
  },
  {
    title: "PORCENTAJE",
    dataIndex: "porcentaje",
    key: "porcentaje",
    slots: { customRender: "porcentaje" },
    defaultSortOrder: "descend",
    sorter: (a, b) => a.porcentaje - b.porcentaje
  }
];

export const columns2 = [
  {
    title: "DISTRITO",
    dataIndex: "nombre",
    key: "nombre"
  },
  {
    title: "TOTAL",
    dataIndex: "total_formateado",
    key: "total_formateado",
    slots: { customRender: "total_formateado" },
    defaultSortOrder: "descend",
    sorter: (a, b) => a.total - b.total
  },
  {
    title: "PORCENTAJE",
    dataIndex: "porcentaje",
    key: "porcentaje",
    slots: { customRender: "porcentaje" },
    defaultSortOrder: "descend",
    sorter: (a, b) => a.porcentaje - b.porcentaje
  }
];

export const columns3 = [
  {
    title: "CENTRO POBLADO",
    dataIndex: "nombre",
    key: "nombre"
  },
  {
    title: "TOTAL",
    dataIndex: "total_formateado",
    key: "total_formateado",
    slots: { customRender: "total_formateado" },
    defaultSortOrder: "descend",
    sorter: (a, b) => a.total - b.total
  },
  {
    title: "PORCENTAJE",
    dataIndex: "porcentaje",
    key: "porcentaje",
    slots: { customRender: "porcentaje" },
    defaultSortOrder: "descend",
    sorter: (a, b) => a.porcentaje - b.porcentaje
  }
];

export const columns4 = [
  {
    title: "ESTABLECIMIENTO",
    dataIndex: "nombre",
    key: "nombre"
  },
  {
    title: "TOTAL",
    dataIndex: "total_formateado",
    key: "total_formateado",
    slots: { customRender: "total_formateado" },
    defaultSortOrder: "descend",
    sorter: (a, b) => a.total - b.total
  },
  {
    title: "PORCENTAJE",
    dataIndex: "porcentaje",
    key: "porcentaje",
    slots: { customRender: "porcentaje" },
    defaultSortOrder: "descend",
    sorter: (a, b) => a.porcentaje - b.porcentaje
  }
];
