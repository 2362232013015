<template>
  <div style="display: none">
    <!-- -------- print -------- -->
    <div id="printCantidadPorcentualTodo">
      <PrintContainer :tituloReporte="tituloReporte">
        <table class="items-center w-full">
          <thead class="theadprint text-xs p-5">
            <tr>
              <th class="text-center p-3 border border-black">NRO A</th>
              <th class="text-center p-3 border border-black">PROVINCIA</th>
              <th class="text-center p-3 border border-black">CANTIDAD</th>
              <th class="text-center p-3 border border-black">PORCENTAJE</th>
            </tr>
          </thead>
          <tbody class="text-xs">
            <tr v-for="(data, index) in dataToPrint.provincia" :key="index">
              <td class="border border-gray-900 text-center py-1">
                {{ index + 1 }}
              </td>
              <td class="border border-gray-900 px-2">
                {{ data.nombre }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ numberWithCommas(data.total) }}
              </td>
              <td class="border border-gray-900 text-center px-2">{{ data.porcentaje }} %</td>
            </tr>
            <tr>
              <td class="border border-gray-900 text-center px-2 font-bold" colspan="2">
                TOTAL REGIONAL
              </td>
              <td class="border border-gray-900 text-center px-2 font-bold">
                {{ totalRegional }}
              </td>
              <td class="border border-gray-900 text-center px-2 font-bold">100%</td>
            </tr>
          </tbody>
          <PrintFooter>
            <tr>
              <td colspan="100%" class="border-0">
                Fuente: PADRÓN NOMINAL actualizado a la fecha: {{ fechaIngreso }}
              </td>
            </tr>
          </PrintFooter>
        </table>

        <!-- Distrito -->
        <table class="items-center w-full">
          <thead class="theadprint text-xs p-5">
            <tr>
              <th class="text-center p-3 border border-black">NRO</th>
              <th class="text-center p-3 border border-black">DISTRITO</th>
              <th class="text-center p-3 border border-black">CANTIDAD</th>
              <th class="text-center p-3 border border-black">PORCENTAJE</th>
            </tr>
          </thead>
          <tbody class="text-xs">
            <tr v-for="(data, index) in dataToPrint.distrito" :key="index">
              <td class="border border-gray-900 text-center py-1">
                {{ index + 1 }}
              </td>
              <td class="border border-gray-900 px-2">
                {{ data.nombre }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ numberWithCommas(data.total) }}
              </td>
              <td class="border border-gray-900 text-center px-2">{{ data.porcentaje }} %</td>
            </tr>
          </tbody>
          <PrintFooter>
            <tr>
              <td colspan="100%" class="border-0">
                Fuente: PADRÓN NOMINAL actualizado a la fecha: {{ fechaIngreso }}
              </td>
            </tr>
          </PrintFooter>
        </table>

        <!-- Centro poblado -->
        <table class="items-center w-full">
          <thead class="theadprint text-xs p-5">
            <tr>
              <th class="text-center p-3 border border-black">NRO</th>
              <th class="text-center p-3 border border-black">CENTRO POBLADO</th>
              <th class="text-center p-3 border border-black">CANTIDAD</th>
              <th class="text-center p-3 border border-black">PORCENTAJE</th>
            </tr>
          </thead>
          <tbody class="text-xs">
            <tr v-for="(data, index) in dataToPrint.centroPoblado" :key="index">
              <td class="border border-gray-900 text-center py-1">
                {{ index + 1 }}
              </td>
              <td class="border border-gray-900 px-2">
                {{ data.nombre }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ numberWithCommas(data.total) }}
              </td>
              <td class="border border-gray-900 text-center px-2">{{ data.porcentaje }} %</td>
            </tr>
          </tbody>
          <PrintFooter>
            <tr>
              <td colspan="100%" class="border-0">
                Fuente: PADRÓN NOMINAL actualizado a la fecha: {{ fechaIngreso }}
              </td>
            </tr>
          </PrintFooter>
        </table>

        <!-- Establecimiento -->
        <table class="items-center w-full">
          <thead class="theadprint text-xs p-5">
            <tr>
              <th class="text-center p-3 border border-black">NRO</th>
              <th class="text-center p-3 border border-black">ESTABLECIMIENTO</th>
              <th class="text-center p-3 border border-black">CANTIDAD</th>
              <th class="text-center p-3 border border-black">PORCENTAJE</th>
            </tr>
          </thead>
          <tbody class="text-xs">
            <tr v-for="(data, index) in dataToPrint.establecimiento" :key="index">
              <td class="border border-gray-900 text-center py-1">
                {{ index + 1 }}
              </td>
              <td class="border border-gray-900 px-2">
                {{ data.nombre }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ numberWithCommas(data.total) }}
              </td>
              <td class="border border-gray-900 text-center px-2">{{ data.porcentaje }} %</td>
            </tr>
          </tbody>
          <PrintFooter>
            <tr>
              <td colspan="100%" class="border-0">
                Fuente: PADRÓN NOMINAL actualizado a la fecha: {{ fechaIngreso }}
              </td>
            </tr>
          </PrintFooter>
        </table>
      </PrintContainer>
    </div>

    <div id="printCantidadPorcentual">
      <PrintContainer :tituloReporte="tituloReporte">
        <p v-if="provinciaFiltrado" class="text-sm text-gray-500">
          <FilterOutlined />provincia {{ provinciaFiltrado }}
        </p>

        <p v-if="distritoFiltrado" class="text-sm text-gray-500">
          <FilterOutlined />distrito {{ distritoFiltrado }}
        </p>

        <table class="items-center w-full">
          <thead class="theadprint text-xs p-5">
            <tr>
              <th class="text-center p-3 border border-black">NRO</th>
              <th class="text-center p-3 border border-black">{{ tituloDeFiltroUpper }}</th>
              <th class="text-center p-3 border border-black">CANTIDAD</th>
              <th class="text-center p-3 border border-black">PORCENTAJE</th>
            </tr>
          </thead>
          <tbody class="text-xs">
            <tr v-for="(data, index) in arraySelectPrint" :key="index">
              <td class="border border-gray-900 text-center py-1">
                {{ index + 1 }}
              </td>
              <td class="border border-gray-900 px-2">
                {{ data.nombre }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ numberWithCommas(data.total) }}
              </td>
              <td class="border border-gray-900 text-center px-2">{{ data.porcentaje }} %</td>
            </tr>
          </tbody>
          <PrintFooter>
            <tr>
              <td colspan="100%" class="border-0">
                Fuente: PADRÓN NOMINAL actualizado a la fecha: {{ fechaIngreso }}
              </td>
            </tr>
          </PrintFooter>
        </table>
      </PrintContainer>
    </div>
  </div>
</template>

<script>
import PrintContainer from "./PrintContainer.vue";
import PrintFooter from "@/components/PrintFooter.vue";
import { FilterOutlined } from "@ant-design/icons-vue";
import { numberWithCommas } from "@/utils";
import { computed, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    PrintContainer,
    PrintFooter,
    // icons
    FilterOutlined
  },
  props: {
    arraySelectPrint: {
      type: Array,
      default: () => []
    },
    dataToPrint: {
      type: Object,
      default: null
    },
    tituloDeFiltro: {
      type: String,
      default: ""
    },
    tituloReporte: {
      type: String,
      default: "REPORTE"
    },
    fechaIngreso: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const provinciaFiltrado = ref("");
    const distritoFiltrado = ref("");
    const totalRegional = ref(0);
    const store = useStore();

    watch(store.state.pacientereporte, () => {
      provinciaFiltrado.value = store.state.pacientereporte.provincia;
      distritoFiltrado.value = store.state.pacientereporte.distrito;
    });

    onMounted(() => {
      let suma = 0;

      props.dataToPrint.provincia.map((data) => {
        suma = suma + Number(data.total);
      });

      totalRegional.value = suma;
    });

    return {
      tituloDeFiltroUpper: computed(() => props.tituloDeFiltro.toUpperCase()),
      numberWithCommas,
      provinciaFiltrado,
      distritoFiltrado,
      totalRegional
    };
  }
};
</script>

<style scoped></style>
